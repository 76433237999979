import React from 'react';
import PropTypes from 'prop-types';
import {
  Link as RouterLink,
} from 'react-router-dom';
import { IconButton, SvgIcon } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import Tooltip from 'src/components/Tooltip';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpCircle from 'src/components/HelpCircle';
import HelpCircleMUI6 from 'src/components/HelpCircleMUI6';

import textFieldFormikStyle from 'src/components/TextFieldFormik/textFieldFormik.style';

function TextFieldFormikContainer({
  children,
  classes,
  showInfo,
  isShowInfoV2,
  disabledBox,
  sizeInput,
  addHighlights,
  helpInfoMarginLeft,
  maxWidthSubtract,
  redirectURL,
  tooltipText,
  handleClickRedirect,
}) {
  const highlightsClass = addHighlights ? ` highlightsType${addHighlights}` : '';

  return (
    <div className={`${classes.root} ${sizeInput}`}>
      <div
        className={`${classes.textField}${disabledBox ? ' disabledBox' : ''}${highlightsClass}`}
        style={{
          ...(maxWidthSubtract ? { maxWidth: `calc(100% - ${maxWidthSubtract}px)` } : {}),
        }}
      >
        {children}
      </div>
      {redirectURL && (
        <div className={classes.redirect}>
          <Tooltip
            title={tooltipText}
            placement="right-start"
          >
            <IconButton
              color="secondary"
              component={RouterLink}
              to={redirectURL}
              onClick={handleClickRedirect ? (e) => handleClickRedirect(e) : (e) => e.stopPropagation()}
              size="large"
            >
              <SvgIcon fontSize="small">
                <SettingsIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </div>
      )}
      {showInfo && (
        <div className={classes.info}>
          {isShowInfoV2 ? (
            <HelpCircleMUI6
              showInfo={showInfo}
              marginLeft={helpInfoMarginLeft}
            />
          ) : (
            <HelpCircle
              showInfo={showInfo}
              marginLeft={helpInfoMarginLeft}
            />
          )}
        </div>
      )}
    </div>
  );
}

TextFieldFormikContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  showInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.bool,
  ]),
  isShowInfoV2: PropTypes.bool,
  children: PropTypes.node,
  disabledBox: PropTypes.bool,
  sizeInput: PropTypes.oneOf([
    'default',
    'small',
  ]),
  addHighlights: PropTypes.oneOf([
    1,
  ]),
  helpInfoMarginLeft: PropTypes.oneOf([
    'default',
    '19',
    '5',
    '0',
  ]),
  maxWidthSubtract: PropTypes.number,
  redirectURL: PropTypes.string,
  tooltipText: PropTypes.string,
  handleClickRedirect: PropTypes.func,
};

TextFieldFormikContainer.defaultProps = {
  showInfo: null,
  isShowInfoV2: false,
  children: null,
  disabledBox: false,
  sizeInput: 'default',
  addHighlights: null,
  helpInfoMarginLeft: 'default',
  maxWidthSubtract: null,
  redirectURL: null,
  tooltipText: null,
  handleClickRedirect: null,
};

export default withStyles(TextFieldFormikContainer, textFieldFormikStyle);
