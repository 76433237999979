import moment from 'moment';
import {
  toArray,
  phoneToInt,
  phoneToString,
  toString
} from 'src/helpers/transform';
import {
  REQUIRE_SUBSCRIPTION_DATE,
} from 'src/constants/global';

export const isEmpty = (value) => {
  try {
    return (
      value === undefined
      || value === ''
      || value === null
      || JSON.stringify(value) === JSON.stringify({})
      || value.length === 0
    );
  } catch (e) {
    return false;
  }
};

export const isNumber = (value) => {
  const result = !Number.isNaN(parseFloat(value)) && Number.isFinite(parseFloat(value));
  if (result) {
    return String(parseFloat(value)).length === String(value).length;
  }

  return false;
};

export const isIntegerNumber = (value) => {
  const result = !Number.isNaN(parseInt(value)) && Number.isFinite(parseInt(value));
  if (result) {
    return String(parseInt(value)).length === String(value).length;
  }

  return false;
};

export const isBirthdayNumber = (value) => !!toString(value || '').match(/^[0-9]+$/gi);

export const isObject = (value) => typeof value === 'object' && value !== null;

export const isString = (value) => typeof value === 'string' || value instanceof String;

export const isArray = (value) => Array.isArray(value);

export const isFunc = (value) => value && {}.toString.call(value) === '[object Function]';

export const inArray = (value, array) => toArray(array).indexOf(value) !== -1;

export const isPhone = (phone) => phone >= 2010000000 && phone <= 9899999999;

export const arePhonesEmpty = (phones) => !toArray(phones).find((item) => isPhone(phoneToInt(item)));

export const isPhoneLengthStandard = (phone) => phoneToString(phone).length < 11;

export const isPageBlocked = (user) => {
  const {
    firstName,
    lastName,
    mobilePhone,
    address1,
    postalCode,
    city,
    country,
    region,
    timeZone,
    userMessagingSystem,
    textingProvider,
  } = user || {};
  const { apiKey } = userMessagingSystem || {};
  const areFieldsFilled = !isEmpty(firstName)
    && !isEmpty(lastName)
    && !isEmpty(mobilePhone)
    && !isEmpty(address1)
    && !isEmpty(postalCode)
    && !isEmpty(city)
    && !isEmpty(country)
    && !isEmpty(region)
    && !isEmpty(timeZone)
    && (!isEmpty(apiKey) || textingProvider !== 'PB');
  return !(isEmpty(user) || areFieldsFilled);
};

export const needOtherInfoNow = (user) => {
  const {
    fbVIPGroupURL,
    personalShoppingURL,
    newCustomerTextPicture,
  } = user || {};
  const {
    regionalCenterPostalCode,
    regionalCenterDistance,
  } = user.userMessagingSystem || {};
  return (isEmpty(newCustomerTextPicture) || isEmpty(fbVIPGroupURL) || isEmpty(personalShoppingURL) || isEmpty(regionalCenterPostalCode) || isEmpty(regionalCenterDistance)) && !isPageBlocked(user);
};

export const needCustomFieldsNow = (user, pbAPIKeyValid) => {
  const {
    userMessagingSystem,
    primaryCompanyCustomFieldsNames,
  } = user || {};
  const {
    customFieldsNames,
  } = userMessagingSystem || {};
  const customFieldsMatch = !primaryCompanyCustomFieldsNames && !customFieldsNames ? true : !primaryCompanyCustomFieldsNames || !customFieldsNames ? false : primaryCompanyCustomFieldsNames.every((customField) => customFieldsNames.includes(customField));
  return !customFieldsMatch && !isPageBlocked(user) && !needOtherInfoNow(user) && pbAPIKeyValid;
};

export const needSubscriptionNow = (user) => {
  const {
    userSubscription,
    subscriptionOff,
    createdAt,
    pbAPIKeyValid,
  } = user || {};
  if (moment(createdAt).isBefore(moment(REQUIRE_SUBSCRIPTION_DATE))) return false;
  return (!subscriptionOff || userSubscription.isSubscriptionActive) && !isPageBlocked(user) && !needOtherInfoNow(user) && !needCustomFieldsNow(user, pbAPIKeyValid);
};

export const isDateAfterCurrent = (timestamp, afterTimestamp = false) => ((afterTimestamp ? moment(afterTimestamp) : moment()).utc().isBefore(moment(timestamp)) ? moment(timestamp) : false);

export const isPaymentRequestActive = (timestamp) => !!(timestamp && isDateAfterCurrent(moment(timestamp).utc().add(30, 'seconds')));

export const doesUserHaveSubscription = (user, userSubscription = false) => {
  const {
    userSubscription: oldUserSubscription,
    subscriptionOff,
  } = user || {};

  if (subscriptionOff) {
    return true;
  }

  const newUserSubscription = userSubscription || oldUserSubscription;

  if (!newUserSubscription) {
    return false;
  }

  const { isSubscriptionActive } = newUserSubscription;

  return !!isSubscriptionActive;
};

export const isEmptyApiKey = (user) => {
  const {
    textingProvider,
    userMessagingSystem,
  } = user || {};
  const {
    apiKey,
  } = userMessagingSystem || {};
  // return isEmpty(user) || isEmpty(apiKey);
  return isEmpty(apiKey) && textingProvider === 'PB';
};

export const isUserAdmin = (user) => {
  const { name: userRoleName } = user?.userRole || {};
  return userRoleName && userRoleName === 'Admin';
};

export const isUnpaidSubscription = (userSubscription) => {
  const {
    subscriptionEndDate,
    isSubscriptionActive,
    currentSubscriptionId,
  } = userSubscription || {};
  return !isSubscriptionActive && !subscriptionEndDate && currentSubscriptionId;
};

export const hasParentClass = (element, parentCssSelectors) => {
  try {
    return element.closest(parentCssSelectors);
  } catch (e) {
    try {
      let node = element;
      const newCssSelectors = parentCssSelectors.slice(1);
      while (node && node !== document) {
        if (node.className && node.className.indexOf(newCssSelectors) !== -1) {
          return node;
        }
        node = node.parentNode;
      }
      return null;
    } catch (error) {
      return null;
    }
  }
};

export const findObjectWithKeyValueString = (array, key, value) => array.find((element) => element[key] && value && element[key].toLowerCase() === value.toLowerCase());

export const isItUserMassageTemplate = (code) => !!(code || '').match(/^usr/);
